import { ExclamationCircleIcon, LockClosedIcon } from "@heroicons/react/solid";
import React from "react";
import logoMsu from "../../img/logos/logo-msu-oficial.svg";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";
import { login } from "../../app/securitySlice";
import { ErrorMessage } from "../../components/FormComponents";

function Login() {
  // let location = useLocation();
  // const history = useHistory();
  // @ts-ignore
  const security = useSelector((state) => state.security);
  const { state } = useLocation();

  return security.authenticated ? (
    <Redirect to={state?.from || "/"} />
  ) : (
    <LoginForm security={security} />
  );
}

export default Login;

//+++++++++++++++++++++++++++++++++++
//
//   Template de la pagina de login
//
//+++++++++++++++++++++++++++++++++++

const LoginForm = ({ security }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    !security.requesting && dispatch(login(data));
  };

  return (
    <div className="flex items-center justify-center min-h-screen px-4 py-12 bg-gray-50 sm:px-6 lg:px-8">
      <div className="w-full max-w-md">
        <div className="pb-4 border-b">
          <div>
            <img
              className="w-auto h-20 mx-auto mb-14"
              src={logoMsu}
              alt="Movimiento Scout del Uruguay"
            />
          </div>

          <h2 className="mt-6 text-2xl font-extrabold text-center text-gray-900 sm:text-3xl">
            Ingresa con tu cuenta Sherpa
          </h2>
          <p className="mt-2 text-sm text-center text-gray-600">
            o{" "}
            <a
              href="https://sherpa.msu.edu.uy/usuarios/registro"
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              registra una nueva cuenta de educador
            </a>
          </p>
        </div>
        {security.error && <ErrorMessage message={security.error} />}
        <form className="mt-4 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4 rounded-md">
            <div>
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700"
              >
                Nombre de usuario
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <input
                  type="text"
                  className={
                    errors.username
                      ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                      : "block w-full pr-10 text-gray-900 placeholder-gray-500 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  }
                  {...register("username", { required: true })}
                />
                {errors.username && (
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <ExclamationCircleIcon
                      className="w-5 h-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {errors.username && (
                <p className="mt-2 text-sm text-red-600">
                  Ingresa un nombre de usuario.
                </p>
              )}
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Contraseña
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <input
                  type="password"
                  className={
                    errors.password
                      ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                      : "block w-full pr-10 text-gray-900 placeholder-gray-500 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  }
                  {...register("password", { required: true })}
                />
                {errors.password && (
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <ExclamationCircleIcon
                      className="w-5 h-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {errors.password && (
                <p className="mt-2 text-sm text-red-600">
                  Ingresa tu contraseña.
                </p>
              )}
            </div>
          </div>

          <div className="text-sm text-right">
            <a
              href="https://sherpa.msu.edu.uy/resetting/request"
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              Olvidé mi contraseña
            </a>
          </div>

          <div>
            <button
              type="submit"
              className={
                security.requesting
                  ? "opacity-30 relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  : "relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              }
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <LockClosedIcon
                  className="w-5 h-5 text-indigo-500 group-hover:text-indigo-400"
                  aria-hidden="true"
                />
              </span>
              Acceder
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
