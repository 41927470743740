import { useMercadopago } from "react-sdk-mercadopago";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import spinner from "./img/icons/spinner-third-regular.svg";
import CCLogos from "./features/order/CCLogos";
import Row from "./features/order/Items/Row";
import { asked, processOrder } from "./app/orderSlice";
import { useState } from "react";
import Currency from "./features/helper/Currency";
import { Link } from "react-router-dom";

function Checkout({ modalAsk4DonationSetVisible, modalAskEmailSetVisible }) {
  // @ts-ignore
  const order = useSelector((state) => state.order);
  const [redirecting, setRedirecting] = useState(false);
  const dispatch = useDispatch();

  // Al tetrminar pregunta si no quiere hacer una donación
  const handleSubmit = () => {
    modalAsk4DonationSetVisible(true);
    //modalAskEmailSetVisible(true);
  };

  //PreferenceId es devuelto por el backend (mercadopago)
  const mercadopago = useMercadopago.v2(
    process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY,
    {
      locale: "es-UY",
    }
  );

  useEffect(() => {
    if (order.initPoint) {
      setRedirecting(true);
      window.location.href = order.initPoint;
    }
  }, [order.initPoint, mercadopago]);

  return (
    <div id="main" className="container max-w-3xl px-4 mx-auto sm:px-6 lg:px-8">
      <div className="p-5 bg-white rounded-md shadow-lg">
        <div className="cho-container" />
        <h1 className="text-xl font-bold md:text-3xl">
          Revisa los detalles de tu pago
        </h1>
        <h1 className="mb-5 text-xl font-semibold md:text-3xl">
          Anualidad {process.env.REACT_APP_ANUALIDAD_YEAR}
        </h1>

        {order.items.map((item, index) => (
          <Row key={index} index={index} item={item} />
        ))}
        <Link
          className="block mt-5 mb-2 text-sm text-blue-600 underline"
          to="/pago"
        >
          + Agregar otra anualidad
        </Link>
        <Link className="block text-sm text-blue-600 underline" to="/donacion">
          + Agregar donación
        </Link>

        <div className="p-4 mt-5 bg-indigo-100">
          <table className="w-full text-sm table-auto">
            <tbody>
              <tr>
                <td className="">SUB-TOTAL</td>
                <td className="text-right">
                  <Currency>{order.subtotal}</Currency>
                </td>
              </tr>
              {order.discount.amount > 0 && (
                <tr>
                  <td>
                    Descuento por nucleo familiar ({order.discount.rate}%)
                  </td>
                  <td className="text-right">
                    <Currency>{order.discount.amount}</Currency>
                  </td>
                </tr>
              )}
              <tr>
                <td className="text-lg font-bold">TOTAL A PAGAR</td>
                <td className="text-lg font-bold text-right">
                  <Currency>{order.total}</Currency>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mt-10 mb-4">
          <button
            onClick={() => !order.requesting && handleSubmit()}
            className={
              order.requesting || redirecting
                ? "inline-flex justify-center w-full px-4 py-4 text-base font-medium text-white bg-gray-400 opacity-30 border border-transparent rounded-md shadow-sm  sm:mt-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                : "inline-flex justify-center w-full px-4 py-4 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 sm:mt-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            }
          >
            {(order.requesting || redirecting) && (
              <img
                alt="loading"
                src={spinner}
                className="w-5 h-5 mr-3 -ml-1 animate-spin"
                aria-hidden="true"
              />
            )}
            CONTINUAR
          </button>
        </div>
      </div>
      <CCLogos className="px-4 my-4" />
      <div className="flex px-4 align-middle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 10 20"
          className="w-6 h-6 mr-2"
        >
          <path
            fillOpacity="0.45"
            d="M7.562.599l.383.317c1.92 1.59 3.81 2.374 5.68 2.374h.6v.6c0 5.633-2.165 9.242-6.473 10.679l-.19.063-.19-.063C3.064 13.132.9 9.523.9 3.89v-.6h.6c1.87 0 3.76-.783 5.68-2.374l.383-.317zm0 1.548c-1.8 1.4-3.62 2.179-5.455 2.32.135 4.725 1.947 7.648 5.455 8.898 3.508-1.25 5.32-4.173 5.455-8.898-1.835-.141-3.656-.92-5.455-2.32zm2.286 2.895l.896.798-4.02 4.513-2.472-2.377.831-.865 1.574 1.513 3.191-3.582z"
          ></path>
        </svg>
        <span className="text-sm text-gray-600">
          Pago seguro por Mercado Pago
        </span>
      </div>
    </div>
  );
}

export default Checkout;
