import { createSlice } from "@reduxjs/toolkit";
import api from "./api";

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    requesting: false,
    error: null,
    items: [],
    total: 0,
    discount: {},
    preferenceId: null,
    initPoint: null,
    asked4donation: false,
  },
  reducers: {
    requestStart: (state, action) => {
      state.requesting = true;
      state.error = "";
    },
    requestSuccess: (state, action) => {
      state.requesting = false;
      state.error = "";
      state.preferenceId = action.payload.preferenceId;
      state.initPoint = action.payload.initPoint; //Este cambio hace que pase un redirect
    },
    requestError: (state, action) => {
      state.requesting = false;
      state.error = action.payload;
      state.preferenceId = null;
    },
    dismissError: (state) => {
      state.error = "";
    },
    asked(state, action) {
      state.asked4donation = action.payload;
    },
    addItem(state, action) {
      //Es un pago de anualidad ...
      if (action.payload.type === "payment") {
        //Lo agrego solo si no tiene ya un pago en la orden
        if (
          !state.items.some(
            (item) =>
              item.type === "payment" &&
              item.member.id === action.payload.member.id
          )
        ) {
          state.items.push({
            ...action.payload,
            year: Number(process.env.REACT_APP_ANUALIDAD_YEAR),
          });
        }
      }
      // Es un pago de donación
      else {
        state.asked4donation = true;
        state.items.push(action.payload);
      }

      calculateTotals(state);
    },
    removeItem(state, action) {
      state.items.splice(action.payload, 1);
      calculateTotals(state);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addItem,
  removeItem,
  requestStart,
  requestSuccess,
  requestError,
  dismissError,
  asked,
} = orderSlice.actions;

//PROCESA PEDIDO CON MERCADOPAGO
//Necesito si o si enviar la orden al backend de sherpa
//porque mercadopago no acepta requests desde el frontend
//sencillamente da error

export const processOrder = (data) => (dispatch) => {
  dispatch(requestStart());
  api
    .post(
      `${process.env.REACT_APP_SHERPA_BASE_URL}/finanzas/boleta/remote`,
      data
    )
    .then((response) => {
      dispatch(requestSuccess(response.data));
    })
    .catch((error) => {
      dispatch(requestError(error.message));
    });
};

export default orderSlice.reducer;

//===============================
//  Calcula los montos de todo el pago
//===============================

function calculateTotals(state) {
  state.subtotal = 0;
  state.discount = { label: "", rate: 0, amount: 0 };
  state.total = 0;

  //-------------PARA CADA PAGO --------------

  state.items.forEach((item) => {
    state.subtotal = state.subtotal + Number(item.amount);
  });

  //Calcula el descuento del pago por nucleo familiar
  const payments = state.items.filter((item) => item.type === "payment");
  let discountable = 0;
  payments.forEach((item) => {
    discountable = discountable + Number(item.amount);
  });

  if (payments.length >= 4)
    state.discount = {
      label: "Nucleo familiar x 4",
      rate: Number(process.env.REACT_APP_ANUALIDAD_X4),
      amount: (discountable * Number(process.env.REACT_APP_ANUALIDAD_X4)) / 100,
    };
  if (payments.length === 3)
    state.discount = {
      label: "Nucleo familiar x 3",
      rate: Number(process.env.REACT_APP_ANUALIDAD_X3),
      amount: (discountable * Number(process.env.REACT_APP_ANUALIDAD_X3)) / 100,
    };
  if (payments.length === 2)
    state.discount = {
      label: "Nucleo familiar x 2",
      rate: Number(process.env.REACT_APP_ANUALIDAD_X2),
      amount: (discountable * Number(process.env.REACT_APP_ANUALIDAD_X2)) / 100,
    };
  state.total = state.subtotal - state.discount.amount;
}
