import { createSlice } from "@reduxjs/toolkit";
import api from "./api";

export const securitySlice = createSlice({
  name: "security",
  initialState: {
    authenticated: false,
    requesting: false,
    error: null,
    user: {},
  },
  reducers: {
    requestTokenStart: (state, action) => {
      state.requesting = true;
      state.error = "";
      state.user = {};
      localStorage.removeItem("token");
    },
    requestUserStart: (state, action) => {
      state.requesting = true;
      state.error = "";
      state.user = {};
    },
    requestTokenSuccess: (state, action) => {
      state.error = "";
      state.user = {};
      localStorage.setItem("token", action.payload.access_token);
    },
    requestUserSuccess: (state, action) => {
      state.authenticated = true;
      state.requesting = false;
      state.error = "";
      state.user = action.payload;
    },
    requestError: (state, action) => {
      const error = action.payload;
      console.log(error);
      if (error.no_group)
        state.error =
          "Esta plataforma solo está disponible para usuarios de un grupo.";
      else if (error.request) state.error = "Error conectando al servidor.";
      else if (
        error.response?.data.error_description ===
        "Invalid username and password combination"
      )
        state.error = "Nombre de usuario o contraseña incorrecta.";
      //else state.error = "Ocurrió un error. Vuelve a intentar luego.";
      // switch (error.response.data.error) {
      //   case "code_required":
      //     console.log("No se envió un código de autorización");
      //     state.error = "No se envió un código de autorización";
      //     break;

      //   default:
      //     console.log("Error")
      //     state.error = "No es posible obtener acceso ahora";
      //     break;
      // }
      localStorage.removeItem("token");
      state.authenticated = false;
      state.requesting = false;
      state.user = {};
    },
    logout: (state, action) => {
      localStorage.removeItem("token");
      state.authenticated = false;
      state.requesting = false;
      state.user = {};
    },
  },
});

export const login = (data) => (dispatch) => {
  dispatch(requestTokenStart());
  // Primero envio a mi backend el authorization code
  // (desde el backend se va a hacer un post al sherpa)
  const params = new URLSearchParams();
  params.append("client_id", process.env.REACT_APP_SHERPA_OAUTH_CLIENT_ID);
  params.append(
    "client_secret",
    process.env.REACT_APP_SHERPA_OAUTH_CLIENT_SECRET
  );
  params.append("grant_type", "password");
  params.append("username", data.username);
  params.append("password", data.password);

  api
    .post(`${process.env.REACT_APP_SHERPA_BASE_URL}/oauth/v2/token`, params)
    // Entonces obtengo el access_token
    .then((response) => {
      dispatch(requestTokenSuccess(response.data));
      // Y con ese token obtengo al usuario
      dispatch(fetchUser());
    })
    .catch((error) => {
      dispatch(requestError(error));
    });
};

export const fetchUser = () => (dispatch) => {
  dispatch(requestUserStart());
  api
    .get(`/api/users/me`)
    .then((response) => {
      if (response.data.group === null)
        dispatch(requestError({ no_group: true }));
      else dispatch(requestUserSuccess(response.data));
    })
    .catch((error) => {
      dispatch(requestError(error));
    });
};

// Action creators are generated for each case reducer function
export const {
  requestError,
  requestTokenStart,
  requestTokenSuccess,
  requestUserStart,
  requestUserSuccess,
  logout,
} = securitySlice.actions;

export default securitySlice.reducer;
