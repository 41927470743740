import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";

const PrivateRoute = (props) => {
  const location = useLocation();

  // useEffect(() => {
  //   //Si no está logueado me manda a loguearme (redirect a sherpa auth)
  //   //pasa el dato de location para que sepa a donde volver
  //   if (!localStorage.getItem("token")) dispatch(login(location));
  // });

  //Si está logueado muestro el contenido
  //Sino me redirige a login
  return localStorage.getItem("token") ? (
    <Route path={props.path} exact={props.exact} component={props.component} />
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: location },
      }}
    />
  );
};
export default PrivateRoute;
