import { BadgeCheckIcon } from "@heroicons/react/solid";
import React from "react";

function Success() {
  return (
    <div className="flex h-screen bg-green-500">
      <div className="m-auto text-center text-white">
        <BadgeCheckIcon className="w-32 h-32 m-auto" />
        <h3 className="mb-4 text-5xl font-bold">¡Listo!</h3>
        <p className="text-xl">
          El pago fue realizado <br />
          correctamente
        </p>
      </div>
    </div>
  );
}

export default Success;
