import { createSlice } from "@reduxjs/toolkit";
import api from "./api";

export const groupsSlice = createSlice({
  name: "groups",
  initialState: {
    requesting: false,
    error: null,
    data: [],
  },
  reducers: {
    requestStart: (state, action) => {
      state.requesting = true;
      state.error = "";
    },
    requestSuccess: (state, action) => {
      state.requesting = false;
      state.error = "";
      state.data = action.payload;
    },
    requestError: (state, action) => {
      state.requesting = false;
      state.error =
        action.payload?.status === 404
          ? "No se encuentran datos."
          : "Error al consultar Sherpa.";
      state.data = [];
    },
  },
});

export const fetchGroups = (data, close) => (dispatch) => {
  dispatch(requestStart());
  api
    .get(`/grupos/active`)
    .then((response) => {
      dispatch(requestSuccess(response.data));
    })
    .catch((error) => {
      dispatch(requestError(error.response));
    });
};

// Action creators are generated for each case reducer function
export const { requestError, requestStart, requestSuccess } =
  groupsSlice.actions;

export default groupsSlice.reducer;
