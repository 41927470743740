import { TrashIcon } from "@heroicons/react/outline";
import React from "react";
import { useDispatch } from "react-redux";
import { removeItem } from "../../../app/orderSlice";
import Currency from "../../helper/Currency";
import { MemberData } from "../../multiorder/NewOrder";

function Row({ item, index }) {
  return item.type === "payment" ? (
    <RowPayment item={item} index={index} />
  ) : (
    <RowDonation item={item} index={index} />
  );
}

function RowPayment({ item, index }) {
  const dispatch = useDispatch();

  return (
    <div className="flex p-2">
      <div className="flex flex-1 ">
        <button onClick={() => dispatch(removeItem(index))}>
          <TrashIcon className="w-5 h-5 mr-2 opacity-40 hover:opacity-100" />
        </button>
        <MemberData member={item.member} />
      </div>
      <div className="w-24 text-sm text-right">
        <Currency>{item.amount}</Currency>
        {item.subvention.rate > 0 && (
          <div className="text-gray-600">
            {item.subvention.label} {item.subvention.rate}%
          </div>
        )}
      </div>
    </div>
  );
}

function RowDonation({ item, index }) {
  const dispatch = useDispatch();

  return (
    <div className="flex p-2">
      <div className="flex flex-1">
        <button onClick={() => dispatch(removeItem(index))}>
          <TrashIcon className="w-5 h-5 mr-2 opacity-40 hover:opacity-100" />
        </button>
        <p className="text-sm">
          Donación al{" "}
          {item.group
            ? `Grupo Scout ${item.group.name}`
            : "Movimiento Scout del Uruguay"}
        </p>
      </div>
      <div className="w-24 text-sm text-right">
        <Currency>{item.amount}</Currency>
      </div>
    </div>
  );
}

export default Row;
