import React from "react";
import { Popover } from "@headlessui/react";
import { usePopper } from "react-popper";
import { useState } from "react";

export default function MoreInfo({ title = null, body }) {
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement);

  return (
    <Popover className="inline-block align-middle">
      <Popover.Button className="ml-1 text-gray-500" ref={setReferenceElement}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </Popover.Button>

      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className="absolute z-10 p-2 mt-2 text-center bg-indigo-600 rounded-lg"
      >
        <p className="text-sm text-white">{body}</p>
      </Popover.Panel>
    </Popover>
  );
}
