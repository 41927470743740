import React from "react";
import people from "./img/people.svg";
import heart from "./img/icons/heart-regular.svg";
import cc from "./img/icons/credit-card-front-regular.svg";
import pdf from "./img/pdf-svgrepo-com.svg";
import CCLogos from "./features/order/CCLogos";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div
      id="main"
      className="container flex flex-1 max-w-5xl px-4 mx-auto sm:px-6 lg:px-8"
    >
      <div className="m-auto md:flex">
        <div className="flex-initial">
          <img src={people} className="w-full mb-10 md:mr-10 md:w-80" alt="" />
        </div>
        <div className="flex-1">
          <h2 className="text-lg font-semibold text-purple-800">
            CAMPAÑA ANUALIDAD {process.env.REACT_APP_ANUALIDAD_YEAR}
          </h2>
          <h1 className="mb-5 text-4xl font-bold md:text-5xl">
            ¡Contamos contigo!
          </h1>
          <div>
            <p className="pb-6 text-base text-justify">
              Cada año el aporte de nuestros miembros permite mantener nuestra
              organización en funcionamiento. Paga tu anualidad ahora para estar
              al día con el Movimiento Scout del Uruguay hasta el 30 de Junio de
              {Number(process.env.REACT_APP_ANUALIDAD_YEAR) + 1}.
            </p>
            <p className="pb-6 text-base text-justify">
              Para conocer en detalle la finalidad de este pago, los plazos y
              montos para las distintas categorías{" "}
              <a
                href="https://msu.edu.uy/pagos/anualidad-2021/"
                rel="noreferrer"
                target="_blank"
                className="text-blue-600 underline"
              >
                hacé click acá.
              </a>
            </p>

            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <Link
                className="flex items-center justify-center w-full px-4 py-4 text-sm font-medium text-white uppercase bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                to="/pago"
              >
                <img
                  alt=""
                  src={cc}
                  className="w-5 h-5 mr-3 -ml-1"
                  aria-hidden="true"
                />
                Quiero pagar mi anualidad
              </Link>

              <Link
                className="flex items-center justify-center w-full px-4 py-4 text-sm font-medium text-gray-800 uppercase bg-gray-300 border border-transparent rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                to="/donacion"
              >
                <img
                  src={heart}
                  alt=""
                  className="w-5 h-5 mr-3 -ml-1 animate-bounce"
                  aria-hidden="true"
                />
                Quiero hacer una donación
              </Link>
            </div>
            <p className="mt-4 text-xs">
              Los grupos scouts pueden hacer el pago de la anualidad de sus
              miembros por{" "}
              <Link to="/group/order" className="text-blue-600 underline">
                transferencia bancaria
              </Link>
            </p>
            <p className="mb-10 text-xs">
              Precio provisorio, sujeto a la aprobación de la Asamblea Nacional{" "}
              {process.env.REACT_APP_ANUALIDAD_YEAR}.
            </p>
            <div className="my-10">
              <h3 className="text-xl font-bold">Guias descargables</h3>
              <div className="flex">
                <img src={pdf} alt="" className="w-5 h-5 mr-3 -ml-1" />
                <a
                  className="text-blue-600 underline"
                  download
                  href="https://msu.edu.uy/wp-content/uploads/2022/04/Instructivo-Anualidad-2022.pdf"
                >
                  Instructivo pago de anualidad (para familias)
                </a>
              </div>
              <div className="flex">
                <img src={pdf} alt="" className="w-5 h-5 mr-3 -ml-1" />
                <a
                  className="text-blue-600 underline"
                  download
                  href="https://msu.edu.uy/wp-content/uploads/2022/04/Instructivo-Pago-Anualidad-Grupal-2022.pdf"
                >
                  Instructivo pago grupal de anualidad (para grupos)
                </a>
              </div>
            </div>

            <CCLogos />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
