import axios from "axios";
import { logout } from "./securitySlice";

const api = axios.create({
  baseURL: process.env.REACT_APP_SHERPA_BASE_URL,
  responseType: "json",
});

let store = null;

export function configureApiStore(s) {
  store = s;
}

const instance = () => {
  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Axios response interceptor
  api.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      //Si al hacer un request obtengo un error 401, borro el token asi mando a login
      if (
        error.response.status === 401
        // &&
        // error.response.data.error_description ===
        //   "The access token provided has expired."
      )
        store.dispatch(logout());
      //En cualquier caso devuelvo el error formateado de la manera que a mi me gusta
      return Promise.reject({
        request: !error.response && !!error.request, // solo devuleve true si hay un error en request. Descarta todo el resto de la info
        response: {
          status: error.response.status,
          data: error.response.data,
          headers: error.response.headers,
        },
      });
    }
  );

  return api;
};

export default instance();

//*********************************************
// Estos interceptores están para estandarizar
// los errores devueltos ya que si es un requesterror
// me devuelve un objeto y no puedo pasar un objeto
// como payload a una acción de redux
//*********************************************
// Los errores de AXIOS son
//
// error.request              XMLHttpRequest (no puedo pasarlo)
// error.response.status      401, 403, 500
// error.response.data        el contenido de la respuesta
// error.response.headers
