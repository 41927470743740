import React from "react";
import WhatsAppLogo from "../img/logos/WhatsApp.svg";
import AvatarDiego from "../img/avatars/diego.svg";
import AvatarJulieta from "../img/avatars/julieta.svg";
import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";

function WhatsAppHelp() {
  const [showDialog, setShowDialog] = useState(false);
  const [show, setShow] = useState(true);

  return (
    <div className={show ? "fixed bottom-6 right-4 z-30" : "hidden"}>
      <button
        className="absolute right-0 inline-flex p-1 text-gray-500 bg-gray-200 rounded-full -top-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() => {
          setShow(false);
        }}
      >
        <span className="sr-only">Close</span>
        <XIcon className="w-3 h-3" aria-hidden="true" />
      </button>
      <button onClick={() => setShowDialog(true)}>
        <div className="absolute hidden p-1 text-xs text-gray-800 bg-white rounded-full sm:visible opacity-70 whitespace-nowrap right-6 -top-2">
          ¿necesitas ayuda?
        </div>
        <img src={WhatsAppLogo} alt="" className="w-14" />
      </button>
      <Dialog show={showDialog} setShow={setShowDialog} />
    </div>
  );
}

export default WhatsAppHelp;

function Dialog({ show, setShow }) {
  /* This example requires Tailwind CSS v2.0+ */
  const operators = [
    {
      name: "Diego Montero",
      phone: "59899473488",
      role: "Soporte Sherpa",
      avatar: AvatarDiego,
      enabled: true,
    },
    {
      name: "Julieta Nogueira",
      phone: "59892991188",
      role: "Administración y finanzas",
      avatar: AvatarJulieta,
      enabled: false,
    },
  ];

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="flex flex-col items-center w-full space-y-4 sm:fixed sm:bottom-24 sm:right-6 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="relative px-4 py-6 text-center text-white bg-green-400">
                <p className="text-sm font-medium">
                  ¿Necesitas ayuda? Mandanos un WhatsApp <br />
                </p>
                <p className="mt-1 text-sm">Estamos siempre listos</p>
                <div className="absolute flex flex-shrink-0 right-2 top-2">
                  <button
                    className="inline-flex text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="w-5 h-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <div className="p-4 text-gray-700 bg-white">
                {operators.map(
                  (operator) => operator.enabled && <Operator {...operator} />
                )}
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}

function Operator({ name, role, phone, avatar }) {
  return (
    <a
      href={`https://wa.me/${phone}?text=${encodeURIComponent(
        "Hola! Necesito ayuda con el pago de la anualidad"
      )}`}
      rel="noreferrer"
      target="_blank"
      className="flex items-center px-3 py-1 my-2 bg-gray-100 rounded-md"
    >
      <img src={avatar} alt="" className="relative w-1/4 mr-4 bottom-1" />
      <div>
        <p className="font-semibold">{name}</p>
        <p className="text-xs">{role}</p>
        <div className="inline-block px-2 py-0.5 text-2xs font-bold text-white bg-green-500 rounded-sm">
          Disponible
        </div>
      </div>
    </a>
  );
}
