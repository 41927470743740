import { configureStore } from "@reduxjs/toolkit";
import orderReducer from "./orderSlice";
import memberReducer from "./memberSlice";
import groupsReducer from "./groupsSlice";
import securityReducer from "./securitySlice";
import groupOrderReducer from "./groupOrderSlice";
import appSliceReducer from "./appSlice";

export default configureStore({
  reducer: {
    app: appSliceReducer,
    order: orderReducer,
    member: memberReducer,
    groups: groupsReducer,
    security: securityReducer,
    groupOrder: groupOrderReducer,
  },
});
