import React from "react";
import visa from "../../img/logos/visa.svg";
import master from "../../img/logos/master.svg";
import oca from "../../img/logos/oca.svg";
import diners from "../../img/logos/diners.svg";
import redpagos from "../../img/logos/redpagos.svg";
import abitab from "../../img/logos/abitab.svg";

function CCLogos({ className = "" }) {
  return (
    <div className={className}>
      <div className="flex-wrap items-center hidden md:flex">
        <img src={visa} className="flex-initial h-4 mr-3" alt="VISA" />
        <img src={master} className="h-5 mr-3" alt="MASTER CARD" />
        <img src={oca} className="h-3 mr-3" alt="OCA" />
        <img src={diners} className="h-5 mr-auto" alt="DINERS CLUB" />
        <img src={redpagos} className="h-4 mr-2" alt="REDPAGOS" />
        <img src={abitab} className="h-5" alt="ABITAB" />
      </div>
    </div>
  );
}

export default CCLogos;
