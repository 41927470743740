import { useEffect, useState } from "react";
import api from "../../app/api";
import Payment from "./Payment";
import NewOrder from "./NewOrder";
import spinner from "../../img/icons/spinner-third-regular.svg";
import PendingOrder from "./PendingOrder";

export type ordertype = {
  id: string;
  pago: "pendiente" | "completo";
  total: number;
  fecha: string;
};

function Order() {
  const [pending, setPending] = useState<null | ordertype[]>(null);
  const [requested, setRequested] = useState(false);

  useEffect(() => {
    api
      .get<ordertype[]>("/api/invoices/pending")
      .then((response) => {
        if (response.data.length === 0) return setPending(null);
        else setPending(response.data);
      })
      .catch((error) => {
        setPending(null);
      })
      .finally(() => {
        setRequested(true);
      });
  }, []);

  if (!requested)
    return (
      <div className="flex flex-col min-h-screen bg-white items-center justify-center">
        <img
          alt="loading"
          src={spinner}
          className="w-10 h-10 mr-3 -ml-1 animate-spin"
          aria-hidden="true"
        />
      </div>
    );

  if (!pending) return <NewOrder />;

  if (pending.length > 0) return <PendingOrder order={pending[0]} />;

  return;
}

export default Order;
