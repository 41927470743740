import { ExclamationCircleIcon } from "@heroicons/react/solid";

//=====================================================
//   Un mensaje de error usado en formularios
//=====================================================
export function ErrorMessage({ message, ...props }) {
  return (
    <div {...props}>
      <div className="flex content-center mt-2">
        <ExclamationCircleIcon
          className="w-5 h-5 mr-2 text-red-500"
          aria-hidden="true"
        />
        <p className="text-sm text-red-600">{message}</p>
      </div>
    </div>
  );
}
