//++++++++++++++++++++++++++++++++++++++++++
//
//     MUESTRA EL DETALLE DE CADA
//     PAGO EN EL CHECKOUT
//
//++++++++++++++++++++++++++++++++++++++++++
import { TrashIcon } from "@heroicons/react/outline";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { useDispatch } from "react-redux";
import { moveMember, removeMember } from "../../../app/groupOrderSlice";
import Currency from "../../helper/Currency";
import { MemberData } from "../NewOrder";

export default function PaymentsTable({ payments }) {
  const dispatch = useDispatch();

  function onDragEnd(result) {
    const { source, destination } = result;

    dispatch(
      moveMember({
        from: {
          paymentIndex: +source.droppableId,
          memberIndex: source.index,
        },
        to: destination
          ? {
              //Drop outside?
              paymentIndex: +destination.droppableId,
              memberIndex: destination.index,
            }
          : null,
      })
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="my-4">
        {payments.map((payment, key) => (
          <Droppable key={key} droppableId={`${key}`}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className={
                  snapshot.isDraggingOver
                    ? "p-2 border-b border-gray-200 bg-gray-100"
                    : "p-2 border-b border-gray-200"
                }
                {...provided.droppableProps}
              >
                {payment.members.map((member, index) => (
                  <Draggable
                    key={member.id}
                    draggableId={`${member.id}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div className="flex my-2">
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="flex w-3/4"
                        >
                          <button
                            onClick={() =>
                              dispatch(
                                removeMember({
                                  paymentIndex: key,
                                  memberIndex: index,
                                })
                              )
                            }
                          >
                            <TrashIcon className="w-5 h-5 mr-2 opacity-40 hover:opacity-100" />
                          </button>
                          <MemberData
                            className="truncate"
                            member={member}
                            key={member.id}
                          />
                        </div>
                        <div className="w-1/4 text-sm text-right">
                          <Currency>{member.amount}</Currency>
                          {member.subvention.rate > 0 && (
                            <div className="text-xs text-gray-600">
                              {member.subvention.label} {member.subvention.rate}
                              %
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}

                {/* <div className="w-1/4 text-sm text-right">
                  <Currency>{total.amount}</Currency>
                  {member.subvention.rate > 0 && (
                    <div className="text-gray-600">
                      {member.subvention.label} {member.subvention.rate}%
                    </div>
                  )}
                </div> */}

                {payment.discount.rate > 0 && (
                  <div className="flex my-2 text-sm text-gray-600">
                    <div className="w-3/4 pl-7">
                      {payment.discount.label} (Dto. {payment.discount.rate}%)
                    </div>
                    <div className="w-1/4 text-sm text-right">
                      <Currency>{-payment.discount.amount}</Currency>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Droppable>
        ))}
      </div>
    </DragDropContext>
  );
}
