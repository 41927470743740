import { createSlice } from "@reduxjs/toolkit";
import api from "./api";
const initialState = {
  year: process.env.REACT_APP_ANUALIDAD_YEAR,
  payments: [],
  subtotal: 0,
  total: 0,
  discount: {
    label: "Descuento para pagos grupales",
    rate: Number(process.env.REACT_APP_ANUALIDAD_GROUP_DISCOUNT),
    amount: 0,
  },
  id: null,
  created: null,
  sending: false,
  error: "",
};

export const groupOrderSlice = createSlice({
  name: "groupOrder",
  initialState: initialState,
  reducers: {
    resetOrder: (state) => {
      Object.assign(state, initialState);
    },
    sendStart: (state, action) => {
      state.sending = true;
      state.error = "";
    },
    sendSuccess: (state, action) => {
      state.sending = false;
      state.error = "";
      state.id = action.payload.invoiceId;
      state.created = new Date();
    },
    sendError: (state, action) => {
      state.sending = false;
      state.error = action.payload;
      state.id = action.payload.id;
      state.created = new Date();
    },
    addMember(state, action) {
      const member = action.payload;
      //No agrego miembros duplicados
      if (
        state.payments.some((payment) => {
          return payment.members.some((item) => item.id === member.id);
        })
      ) {
        state.error = "El miembro ya está en la lista.";
      }

      //No agrego miembros que tengan su anualidad paga
      else if (member.annualFeePaid) {
        state.error = "El miembro ya pagó su anualidad.";
      } else {
        //Calcula el monto que le corresponde pagar al miembro
        //Por su categoria
        member.price =
          member.type === "educando"
            ? Number(process.env.REACT_APP_ANUALIDAD_EDUCANDO)
            : Number(process.env.REACT_APP_ANUALIDAD_ADULTO);
        //Si tiene una beca la aplica ...
        let subvention = member.subventions.find(
          (subvention) =>
            subvention.year === Number(process.env.REACT_APP_ANUALIDAD_YEAR)
        );
        member.subvention = subvention
          ? {
              label: "BECA",
              rate: subvention.discount,
              amount: (subvention.discount * member.price) / 100,
            }
          : { label: "BECA", rate: 0, amount: 0 };

        member.amount = member.price - member.subvention.amount;

        //Agrega el mimebro y sus valores a la lista
        state.payments.push({
          id: state.payments.length + 1,
          members: [member],
        });
        calculateTotals(state);
      }
    },
    moveMember(state, action) {
      //Toma el miembro del pago de origen
      const [removed] = state.payments[
        action.payload.from.paymentIndex
      ].members.splice(action.payload.from.memberIndex, 1);

      //Muevo el miembro al destino
      if (action.payload.to !== null) {
        //Hay un destino definido
        state.payments[action.payload.to.paymentIndex].members.splice(
          state.payments[action.payload.to.paymentIndex].members,
          0,
          removed
        );
      } else {
        //No hay un destino (soltó fuera de cualquier pago)
        //Creo un pago nuevo
        state.payments.push({
          id: state.payments.length + 1,
          members: [removed],
          subtotal: 0,
          discount: {},
          total: 0,
        });
      }

      //Si el origen quedó vacio borro el pago
      if (
        state.payments[action.payload.from.paymentIndex].members.length === 0
      ) {
        state.payments.splice(action.payload.from.paymentIndex, 1);
      }
      calculateTotals(state);
    },
    removeMember(state, action) {
      state.payments[action.payload.paymentIndex].members.splice(
        action.payload.memberIndex,
        1
      );
      //Si el origen quedó vacio borro el pago
      if (state.payments[action.payload.paymentIndex].members.length === 0) {
        state.payments.splice(action.payload.paymentIndex, 1);
      }
      calculateTotals(state);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  resetOrder,
  addMember,
  moveMember,
  removeMember,
  sendStart,
  sendSuccess,
  sendError,
} = groupOrderSlice.actions;

//PROCESA PEDIDO CON SHERPA
export const sendOrder = (data) => (dispatch) => {
  dispatch(sendStart());
  if (
    data.payments.length <
    Number(process.env.REACT_APP_ANUALIDAD_GROUP_MIN_PAYMENTS)
  )
    dispatch(
      sendError(
        `Debes agregar al menos ${process.env.REACT_APP_ANUALIDAD_GROUP_MIN_PAYMENTS} pagos a la lista.`
      )
    );
  else {
    api
      .post(`/api/invoice`, data)
      .then((response) => {
        dispatch(sendSuccess(response.data));
      })
      .catch((error) => {
        dispatch(sendError(error));
      });
  }
};

export default groupOrderSlice.reducer;

//===============================
//  Calcula los montos en toda
//  la orden. Llamada desde varios
//  lugares
//===============================

function calculateTotals(state) {
  state.subtotal = 0;
  state.total = 0;

  //-------------PARA CADA PAGO --------------
  state.payments.forEach((payment) => {
    payment.subtotal = 0; //El subtotal del pago
    payment.members.forEach((member) => {
      payment.subtotal = payment.subtotal + member.amount;
    });

    //Calcula el descuento del pago por nucleo familiar
    payment.discount = { label: "", rate: 0, amount: 0 };

    if (payment.members.length >= 4)
      payment.discount = {
        label: "Nucleo familiar x 4",
        rate: Number(process.env.REACT_APP_ANUALIDAD_X4),
        amount:
          (payment.subtotal * Number(process.env.REACT_APP_ANUALIDAD_X4)) / 100,
      };
    if (payment.members.length === 3)
      payment.discount = {
        label: "Nucleo familiar x 3",
        rate: Number(process.env.REACT_APP_ANUALIDAD_X3),
        amount:
          (payment.subtotal * Number(process.env.REACT_APP_ANUALIDAD_X3)) / 100,
      };
    if (payment.members.length === 2)
      payment.discount = {
        label: "Nucleo familiar x 2",
        rate: Number(process.env.REACT_APP_ANUALIDAD_X2),
        amount:
          (payment.subtotal * Number(process.env.REACT_APP_ANUALIDAD_X2)) / 100,
      };
    payment.total = payment.subtotal - payment.discount.amount;

    state.subtotal += payment.total;
  });
  //-------------END PARA CADA PAGO --------------

  state.discount.amount = (state.subtotal * state.discount.rate) / 100;
  state.payments.forEach((payment) =>
    payment.members.forEach((member) => {
      if (member.subvention.rate > 0)
        state.discount.amount -= (member.amount * state.discount.rate) / 100;
    })
  );
  state.total = Math.ceil(state.subtotal - state.discount.amount);
}
