import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import headerImg from "./img/header-anualidad.svg";
import PopOverInfo from "./components/PopOverInfo";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

export default function IntroModal({ open }) {
  //Uso este truco para que solo se muestre una vez
  //Una vez que cambie el valor de open(que viene del match del route)
  //haciendo que el modal se cierre, setea show en false, haciendo que no se vuelva a mostrar
  const [show, setShow] = useState(true);
  useEffect(() => {
    !open && setShow(false);
  }, [open]);

  return (
    <Transition.Root show={open && show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => setShow(false)}
      >
        <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-800 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block overflow-hidden text-left text-white align-bottom transition-all transform rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6 beautyModal">
              <div className="absolute top-0 right-0 pt-3 pr-3">
                <button
                  type="button"
                  className="focus:outline-none focus:ring-0 "
                  onClick={() => setShow(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex">
                <img
                  src={headerImg}
                  alt={`ANUALIDAD ${process.env.REACT_APP_ANUALIDAD_YEAR}`}
                  className="w-64 mx-auto my-10 sm:my-0"
                />

                <div className="p-4 sm:ml-4">
                  <div className="flex-1">
                    <div className="flex space-x-2">
                      <PriceBlock
                        category="Educandos"
                        description={
                          <span>
                            Niños, niñas <br />y jovenes
                          </span>
                        }
                        price={process.env.REACT_APP_ANUALIDAD_EDUCANDO}
                      />

                      <PriceBlock
                        category="Voluntarios"
                        description="Educadores y colaboradores"
                        price={process.env.REACT_APP_ANUALIDAD_ADULTO}
                      />
                    </div>
                    <div className="py-2 text-sm italic">
                      * aplican descuentos por núcleo familiar{" "}
                      <PopOverInfo className="w-4 h-4 align-middle focus:ring-0 focus:outline-none">
                        <div>Familia de 2 miembros: 5%</div>
                        <div>Familia de 3 miembros: 10%</div>
                        <div>Familia de 4 miembros o más: 15%</div>
                      </PopOverInfo>
                    </div>
                  </div>
                  <div>
                    <Link
                      to="/pago"
                      className="block w-full px-4 py-2 text-lg text-center border border-white rounded-lg"
                    >
                      PAGAR ONLINE AHORA
                    </Link>
                  </div>
                </div>
              </div>
              <div className="mt-4 text-sm sm:flex sm:items-start justify-end">
                <div className="p-4 flex-1">
                  {/* <h4 className="font-bold">EN CASA SCOUT</h4>
                  <p className="text-justify">
                    Puedes pagar con efectivo o tarjeta en Andrés Martinez
                    Trueba 1187. De lunes a viernes de 18:00 a 21:00.
                  </p> */}
                </div>
                <div className="p-4 flex-1">
                  <h4 className="font-bold">EN TU GRUPO</h4>
                  <p className="text-justify">
                    Puedes abonar la anualidad en tu grupo y ellos se encargarán
                    de hacer el pago en tu nombre.
                  </p>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function PriceBlock({ category, description, price }) {
  return (
    <Link
      to="/pago"
      className="relative flex flex-col p-2 mb-4 text-center rounded-md sm:mb-0"
    >
      <div className="absolute top-0 left-0 z-0 w-full h-full bg-black rounded-md opacity-40"></div>
      <div className="z-10 text-2xl font-semibold">{category}</div>
      <div className="z-10 text-xs uppercase">{description}</div>
      <div className="relative z-10 text-6xl font-extrabold">
        <span className="relative text-3xl opacity-80 bottom-1/4">$</span>
        {price}
      </div>
    </Link>
  );
}
