import { Popover } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { usePopper } from "react-popper";

export default function PopOverInfo({ children, ...props }) {
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top",
  });
  return (
    <div className="inline-block">
      <Popover>
        {({ open }) => (
          <>
            <Popover.Button ref={setReferenceElement} {...props}>
              <InformationCircleIcon />
            </Popover.Button>

            <Popover.Panel
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="z-50"
            >
              <div className="px-4 py-2 overflow-hidden text-sm not-italic bg-black rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                {children}
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  );
}
