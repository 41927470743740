import React from "react";
import { ordertype } from "./Index";
import { useDispatch } from "react-redux";
import { resetOrder } from "../../app/groupOrderSlice";
import { BadgeCheckIcon, DocumentIcon, XIcon } from "@heroicons/react/outline";
import Currency from "../helper/Currency";

//++++++++++++++++++++++++++++++++++++++++++
//
//     MUESTRA MENSAJE FINAL DE EXITO
//
//++++++++++++++++++++++++++++++++++++++++++
function Success({ order }: { order: ordertype }) {
  const dispatch = useDispatch();

  return (
    <div className="flex min-h-screen p-2 bg-green-500">
      <div className="absolute top-0 right-0 hidden pt-3 pr-3 text-white sm:block">
        <button
          type="button"
          className="focus:outline-none focus:ring-0 "
          onClick={() => dispatch(resetOrder())}
        >
          <span className="sr-only">Close</span>
          <XIcon className="w-6 h-6" aria-hidden="true" />
        </button>
      </div>
      <div className="max-w-2xl m-auto text-center text-white">
        <BadgeCheckIcon className="w-32 h-32 m-auto" />
        <h3 className="mb-4 text-5xl font-bold">¡Pronto!</h3>
        <p className="mb-4 text-xl">
          El pago fue confirmado, las anualidades aparecerán como pagas en el
          sherpa
        </p>

        <a
          href={`${process.env.REACT_APP_SHERPA_BASE_URL}/finanzas/order/${order.id}/pdf`}
          className="flex items-center justify-center p-4 mt-4 text-white bg-indigo-600 rounded-md mt-8"
        >
          <div>
            <DocumentIcon className="w-10 mr-3 text-white animate-bounce" />
          </div>
          <span className="font-semibold uppercase md:text-lg">
            Descargar comprobante
          </span>
        </a>
      </div>
    </div>
  );
}

export default Success;
