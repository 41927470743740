import { faCloudUpload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BadgeCheckIcon, XIcon } from "@heroicons/react/outline";
import classnames from "classnames";
import { useState } from "react";
import { useDispatch } from "react-redux";
import api from "../../app/api";
import { resetOrder } from "../../app/groupOrderSlice";
import { useUploader } from "../../hooks/useUploader";
import Currency from "../helper/Currency";
import { ordertype } from "./Index";
import Success from "./Success";
import { useHistory } from "react-router-dom";

//++++++++++++++++++++++++++++++++++++++++++
//
//     MUESTRA MENSAJE FINAL DE EXITO
//
//++++++++++++++++++++++++++++++++++++++++++
function PendingOrder({ order }: { order: ordertype }) {
  const dispatch = useDispatch();
  const { upload, uploadedFile, uploading } = useUploader();
  const [success, setSuccess] = useState<boolean>(false);
  const history = useHistory();

  const sendData = () => {
    if (!uploadedFile) return alert("No has subido el comprobante");
    api
      .post(`/api/invoices/${order.id}/payment`, {
        url: uploadedFile,
      })
      .then((response) => {
        setSuccess(true);
      });
  };

  const cancelOrder = () => {
    api.post(`/api/invoices/${order.id}/delete`).then((response) => {
      history.push("/group/order");
    });
  };
  if (success) return <Success order={order} />;

  return (
    <div className="flex min-h-screen p-2 bg-yellow-400">
      <div className="absolute top-0 right-0 hidden pt-3 pr-3 text-white sm:block">
        <button
          type="button"
          className="focus:outline-none focus:ring-0 "
          onClick={() => dispatch(resetOrder())}
        >
          <span className="sr-only">Close</span>
          <XIcon className="w-6 h-6" aria-hidden="true" />
        </button>
      </div>
      <div className="max-w-2xl w-full m-auto text-white">
        <BadgeCheckIcon className="w-32 h-32 m-auto" />
        <h3 className="mb-4 text-3xl sm:text-5xl font-bold text-center">
          ¡Ahora confirma el <br />
          pago de tu orden!
        </h3>
        <p className="mb-4 text-lg sm:text-xl text-justify">
          La orden fue registrada a la espera de que hagas la transferencia
          bancaria. Recuerda incluir el número de refencia{" "}
          <span className="p-1 bg-yellow-300 rounded-md">{order.id}</span> al
          hacerlo. Las anualidades no aparecerán como pagas hasta que se haya
          confirmado el pago y cargado el comprobante.
        </p>

        <div className="my-8">
          <p className="mb-1 font-bold">
            Banco de la Republica Oriental del Uruguay (BROU)
          </p>
          <p className="mb-1">
            <span>Número de cuenta:</span>
            <div className="p-1 bg-yellow-400 rounded-md md:inline">
              001762259-00001
            </div>
          </p>
          <p className="mb-1">
            <span>Número anterior:</span>
            <div className="p-1 bg-yellow-400 rounded-md md:inline">
              190-0019991
            </div>
          </p>
          <p className="mb-1">
            <span>Importe:</span>
            <div className="p-1 bg-yellow-400 rounded-md md:inline">
              <Currency>{order.total}</Currency>
            </div>
          </p>
          <p>
            <span>Asunto:</span>
            <div className="p-1 bg-yellow-400 rounded-md md:inline">
              {order.id}
            </div>
          </p>
        </div>

        <div className="p-4 font-mono text-center text-gray-800 bg-yellow-100 border-2 border-yellow-500 border-dashed shadow-md md:text-left">
          <input
            type="file"
            onChange={(e) => upload(e.target.files)}
            disabled={uploading}
          />

          <button
            onClick={() => uploadedFile && !uploading && sendData()}
            className={classnames(
              "flex  mt-4 items-center justify-center px-4 py-2 text-white bg-gray-600 rounded-md",
              uploading || !uploadedFile
                ? "bg-gray-400 cursor-not-allowed"
                : "hover:bg-gray-700"
            )}
          >
            <div>
              <FontAwesomeIcon
                icon={faCloudUpload}
                className="h-4 mr-2 text-white animate-bounce"
              />
            </div>
            <span className="font-semibold md:text-lg">
              Cargar confirmación de pago
            </span>
          </button>
          <button
            onClick={() => cancelOrder()}
            className="underline text-sm mt-4"
          >
            Cancelar orden y empezar de nuevo
          </button>
        </div>
      </div>
    </div>
  );
}

export default PendingOrder;
