import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import spinner from "../../img/icons/spinner-third-regular.svg";
import { addMember, sendOrder, resetOrder } from "../../app/groupOrderSlice";
import Currency from "../helper/Currency";
import CheckBox from "../../components/Forms/CheckBox";
import {
  XIcon,
  ArrowUpIcon,
  BadgeCheckIcon,
  DocumentTextIcon,
  ExclamationCircleIcon,
  DocumentIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { Footer, Header } from "../../Main";
import PaymentsTable from "./Order/List";
import MemberSearchInput from "./MemberSearchInput";
import PendingOrder from "./PendingOrder";

export default function NewOrder() {
  // @ts-ignore
  const order = useSelector((state) => state.groupOrder);

  return order.id ? (
    order.error ? (
      <Failed order={order} />
    ) : (
      <PendingOrder order={order} />
    )
  ) : (
    <div className="flex flex-col min-h-full bg-white">
      <Header />
      <Checkout order={order} />
      <Footer />
    </div>
  );
}

//++++++++++++++++++++++++++++++++++++++++++
//
//     MUESTRA LA LISTA DE PAGOS
//     EL TOTAL Y EL BOTÓN DE PAGO
//
//++++++++++++++++++++++++++++++++++++++++++

function Checkout({ order }) {
  const dispatch = useDispatch();

  return (
    <div
      id="main"
      className="container flex-1 max-w-3xl px-4 mx-auto sm:px-6 lg:px-8"
    >
      <h1 className="mb-5 text-xl font-bold md:text-3xl">
        Pago grupal de anualidades {process.env.REACT_APP_ANUALIDAD_YEAR}
      </h1>
      <MemberSearchInput
        onChange={(member) => dispatch(addMember(member))}
        error={order.error}
      />

      {order.payments.length === 0 ? <Empty /> : <Details order={order} />}
    </div>
  );
}

// //++++++++++++++++++++++++++++++++++++++++++
// //
// //     MUESTRA EL DETALLE DE CADA
// //     PAGO EN EL CHECKOUT
// //
// //++++++++++++++++++++++++++++++++++++++++++

function Details({ order }) {
  const dispatch = useDispatch();
  const [check, setCheck] = useState(false);

  return (
    <>
      <PaymentsTable payments={order.payments} />
      <div className="flex mt-3 mb-5 text-sm text-gray-400">
        <InformationCircleIcon className="w-5 h-5 mr-2" />
        Arrastra para agrupar miembros por núcleo familiar.
      </div>
      <div className="p-4 bg-indigo-100">
        <table className="w-full text-sm table-auto">
          <tbody>
            <tr>
              <td className="">SUB-TOTAL</td>
              <td className="text-right">
                <Currency>{order.subtotal}</Currency>
              </td>
            </tr>
            {order.discount.amount > 0 && (
              <tr>
                <td>Descuento por pago grupal ({order.discount.rate}%)</td>
                <td className="text-right">
                  <Currency>{order.discount.amount}</Currency>
                </td>
              </tr>
            )}
            <tr>
              <td className="text-lg font-bold">TOTAL A PAGAR</td>
              <td className="text-lg font-bold text-right">
                <Currency>{order.total}</Currency>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p className="text-xs italic text-gray-600">
                  * No se aplica el descuento grupal a los miembros con beca
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mt-4 mb-10">
        <CheckBox
          label=""
          checked={check}
          onChange={setCheck}
          hint="Entiendo que para completar el pago tendré que hacer una
            transferencia bancaria indicando el numero de referencia que se me brindará en el siguiente paso."
        />
      </div>

      <div className="mb-4">
        <button
          onClick={() => !order.sending && check && dispatch(sendOrder(order))}
          className={
            check
              ? "inline-flex justify-center w-full px-4 py-4 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 sm:mt-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              : "inline-flex justify-center w-full px-4 py-4 text-base font-medium text-white bg-gray-400 opacity-30 border border-transparent rounded-md shadow-sm  sm:mt-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          }
        >
          {order.sending && (
            <img
              alt="loading"
              src={spinner}
              className="w-5 h-5 mr-3 -ml-1 animate-spin"
              aria-hidden="true"
            />
          )}
          CONTINUAR
        </button>
      </div>
    </>
  );
}

//++++++++++++++++++++++++++++++++++++++++++
//
//     MUESTRA LA LISTA VACIA
//
//++++++++++++++++++++++++++++++++++++++++++

export function Empty() {
  return (
    <div className="container flex-1 max-w-3xl px-4 m-auto sm:px-6 lg:px-8">
      <div className="relative px-12 py-24 text-center">
        <ArrowUpIcon
          className="w-8 mx-auto text-gray-400 animate-bounce"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        />
        <DocumentTextIcon
          className="w-20 mx-auto text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        />

        <span className="block mt-2 text-sm font-medium text-gray-800">
          AGREGA EL PRIMER
          <br /> PAGO A LA LISTA
        </span>
      </div>
    </div>
  );
}

export function MemberData({ member, ...props }) {
  return (
    <div {...props}>
      <p className="text-sm truncate">{member.name}</p>
      <p className="text-sm text-gray-800">
        {member.document}{" "}
        {member.type !== "educando" && (
          <span className="p-1 text-white uppercase bg-indigo-400 rounded-md text-2xs">
            {member.type}
          </span>
        )}
      </p>
    </div>
  );
}

//++++++++++++++++++++++++++++++++++++++++++
//
//     MUESTRA MENSAJE FINAL DE ERROR
//
//++++++++++++++++++++++++++++++++++++++++++

function Failed({ order }) {
  return (
    <div className="flex h-screen bg-red-500">
      <div className="m-auto text-center text-white">
        <ExclamationCircleIcon className="w-32 h-32 m-auto" />
        <h3 className="mb-4 text-5xl font-bold">Algo falló</h3>
        <p className="text-xl">
          El pago no pudo <br />
          completarse
        </p>
      </div>
    </div>
  );
}
