export default function CheckBox({
  name = null,
  label,
  hint,
  checked,
  onChange,
}) {
  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <input
          aria-describedby="comments-description"
          name={name}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          type="checkbox"
          className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={name} className="font-medium text-gray-700">
          {label}
        </label>
        <p className="text-gray-500">{hint}</p>
      </div>
    </div>
  );
}
