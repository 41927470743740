import { Switch, Route } from "react-router-dom";

import "./App.css";

import Main from "./Main";
import Success from "./features/order/Success";
import Failed from "./features/order/Failed";
import PrivateRoute from "./features/security/PrivateRoute";
// import Order from "./features/multiorder/Order";
import Login from "./features/security/Login";
import { useDispatch, useSelector } from "react-redux";
import Notification from "./features/helper/Notification";
import { useEffect } from "react";
import { fetchUser } from "./app/securitySlice";
import Logout from "./features/security/Logout";
import IntroModal from "./IntroModal";
import AddPaymentModal from "./features/order/AddPaymentModal";
import AddDonationModal from "./features/order/AddDonationModal";
import React from "react";
import { useState } from "react";
import WhatsAppHelp from "./features/WhatsAppHelp";
import Payment from "./features/multiorder/Payment";
import Order from "./features/multiorder/Index";

function App() {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [showInitModal, setShowInitModal] = useState(true);

  //Al cargar obtiene los datos del usuario (si tengo el access token en el localstorage)
  useEffect(() => {
    localStorage.getItem("token") && dispatch(fetchUser());
  }, [dispatch]);

  return (
    <>
      {state.app.errors.map((error) => (
        <Notification type="error" title={error} />
      ))}
      <Switch>
        <Route exact path="/failed">
          <Failed />
        </Route>
        <Route exact path="/success">
          <Success />
        </Route>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path="/logout" exact>
          <Logout />
        </Route>
        {/* <PrivateRoute path="/test" component={Order} /> */}
        <PrivateRoute path="/group/order" component={Order} />
        {/* <PrivateRoute path="/group/payments/:orderId" component={Payment} /> */}
        <Route path="/">
          <Main />
        </Route>
      </Switch>

      <Route exact path="/pago">
        {({ match }) => <AddPaymentModal open={match != null} />}
      </Route>
      <Route exact path="/donacion">
        {({ match }) => <AddDonationModal open={match != null} />}
      </Route>
      <Route exact path="/">
        {({ match }) => <IntroModal open={match != null} />}
      </Route>
      <WhatsAppHelp />
    </>
  );
}

export default App;
