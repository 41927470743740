import React, { useEffect, useRef, useState } from "react";
import api from "../../app/api";
import Identicon from "react-identicons";
import { ErrorMessage } from "../../components/FormComponents";
import { PlusIcon } from "@heroicons/react/outline";

export default function MemberSearchInput({ onChange, error }) {
  const inputRef = useRef(null);
  const [localError, setLocalError] = useState("");
  const [term, setTerm] = useState("");

  const [cachedResults, setCachedResults] = useState([]);
  const [searchResult, setSearchResult] = useState({
    term: "",
    error: "",
    searching: false,
    members: [],
  });

  useEffect(() => {
    setLocalError(error); //Me mandan un error desde fuera
  }, [error]);

  //======= CADA VEZ QUE CAMBIO EL TEXTO DE BUSQUEDA ========
  useEffect(() => {
    let search = term.trim();
    //Campo de busqueda en blanco ... reseteo resultado
    if (search === "") {
      setSearchResult({ term: "", searching: false, error: "", members: [] });
      return;
    }
    //Voy a buscar resultado, asi que borro si hay un error y empiezo la busqueda
    setLocalError("");
    //Primero veo si ya tengo el resultado en las respuestas del cache
    const result = cachedResults.find((element) => element.term === search);
    if (result) setSearchResult(result);
    //No lo tengo, asi que consulto a la API
    else {
      setCachedResults((prevState) => [
        ...prevState,
        { term: search, searching: true, error: "", members: [] },
      ]);
      api
        .get(
          `/api/members/search?term=${encodeURIComponent(search)}&year=${
            process.env.REACT_APP_ANUALIDAD_YEAR
          }`
        )
        .then((response) => {
          setCachedResults((prevState) =>
            prevState.map((result) =>
              result.term === search
                ? {
                    term: search,
                    members: response.data.result,
                    searching: false,
                  }
                : result
            )
          );
        })
        .catch(() => {
          setLocalError("Error al consultar la base de datos.");
          setCachedResults((prevState) =>
            prevState.map((result) =>
              result.term === search
                ? {
                    term: search,
                    members: [],
                    searching: false,
                  }
                : result
            )
          );
        });
    }
  }, [term, cachedResults]);

  //============== Hago click arriba de un miembro =============
  const memberSelect = (member) => {
    setTerm(""); //Cierro los resultados
    onChange(member); //Indico el miembro elegido
    inputRef.current.focus();
  };

  return (
    <div className="flex flex-col">
      <div className="relative my-2">
        <input
          ref={inputRef}
          type="text"
          value={term}
          placeholder="Busca un miembro por su nombre o documento ..."
          onChange={(e) => setTerm(e.target.value)}
          className="block w-full pl-10 placeholder-gray-500 bg-gray-100 border-0 rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:border-indigo-500 sm:text-sm"
        />
        <div className="absolute inset-y-0 flex items-center pointer-events-none left-2">
          <PlusIcon className="w-8 h-8 text-gray-400" aria-hidden="true" />
        </div>
      </div>

      <div className="relative flex-1">
        {localError ? (
          <ErrorMessage message={localError} />
        ) : (
          <MembersList
            members={searchResult.members}
            memberSelect={memberSelect}
          />
        )}
        {searchResult.searching && (
          <div className="absolute z-10 w-full px-2 bg-white border border-t-0 border-gray-200 drop-shadow-md">
            <PlaceHolder />
            <PlaceHolder />
            <PlaceHolder />
          </div>
        )}
      </div>
    </div>
  );
}

function MembersList({ members, memberSelect }) {
  return (
    <div
      className={
        members.length > 0 &&
        "z-10 absolute w-full px-2 bg-white border border-t-0 border-gray-200 drop-shadow-md"
      }
    >
      {members.map((member) => (
        <button
          key={member.id}
          className="flex content-center w-full p-3 text-left border-b"
          onClick={() => memberSelect(member)}
        >
          {member.avatar ? (
            <img
              className="inline-block w-10 h-10 rounded-full"
              src={`https://sherpa.msu.edu.uy/${member.avatar}`}
              alt=""
            />
          ) : (
            <Identicon
              size={36}
              className="inline-block mt-1 rounded-full"
              string={member.document}
              bg="#d0dee5"
              fg="#a6b1b7"
            />
          )}
          <div className="ml-3">
            <p>{member.name}</p>
            <p className="text-sm text-gray-700">
              {member.document}{" "}
              {member.type !== "educando" && (
                <span className="p-1 text-white uppercase bg-indigo-500 rounded-sm text-2xs">
                  {member.type}
                </span>
              )}
            </p>
          </div>
        </button>
      ))}
    </div>
  );
}

function PlaceHolder() {
  return (
    <div className="w-full max-w-sm p-4 ml-12">
      <div className="flex space-x-4 animate-pulse">
        <div className="flex-1 py-1 space-y-4">
          <div className="w-3/4 h-4 bg-gray-200 rounded"></div>
          <div className="space-y-2">
            <div className="h-4 bg-gray-200 rounded"></div>
            <div className="w-5/6 h-4 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
