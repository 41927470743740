import { ExclamationCircleIcon } from "@heroicons/react/solid";
import React from "react";

function Failed() {
  return (
    <div className="flex h-screen bg-red-500">
      <div className="m-auto text-center text-white">
        <ExclamationCircleIcon className="w-32 h-32 m-auto" />
        <h3 className="mb-4 text-5xl font-bold">Algo falló</h3>
        <p className="text-xl">
          El pago no pudo <br />
          completarse
        </p>
      </div>
    </div>
  );
}

export default Failed;
