import React from "react";

function Currency({ children }) {
  return (
    <span>
      {new Intl.NumberFormat("es-UY", {
        style: "currency",
        currency: "UYU",
      }).format(children)}
    </span>
  );
}

export default Currency;
