import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    errors: [],
    initialModalDismissed: false,
  },
  reducers: {
    addError: (state, action) => {
      state.errors.push(action.payload);
    },
    dismissInitialModal: (state) => {
      state.initialModalDismissed = true;
    },
  },
});

export const { addError, dismissInitialModal } = appSlice.actions;

export default appSlice.reducer;
