import React, { useState } from "react";
import Home from "./Home";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGroups } from "./app/groupsSlice";
import Checkout from "./Checkout";
import logo from "./img/logos/logo-msu-oficial.svg";
import wosm from "./img/logos/wosm.svg";
import fb from "./img/logos/fb.svg";
import tw from "./img/logos/tw.svg";
import insta from "./img/logos/insta.svg";
import microsoft from "./img/logos/microsoft.svg";
import mercadopago from "./img/logos/mercadopago.svg";
import heart from "./img/icons/heart-regular.svg";
import AddDonationModal from "./features/order/AddDonationModal";
import AddPaymentModal from "./features/order/AddPaymentModal";
import Ask4DonationModal from "./features/order/Ask4DonationModal";
import Notification from "./features/helper/Notification";
import UserBlock from "./features/security/UserBlock";
import Ask4EmailModal from "./features/order/Ask4EmailModal";

function Main() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const [modalPaymentVisible, modalPaymentSetVisible] = useState(false);
  const [modalDonationVisible, modalDonationSetVisible] = useState(false);
  const [modalAsk4DonationVisible, modalAsk4DonationSetVisible] =
    useState(false);
  const [modalAskEmail, modalAskEmailSetVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchGroups());
  }, [dispatch]);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      {state.order.error && (
        <Notification type="error" title={state.order.error} />
      )}
      {state.groups.error && (
        <Notification type="error" title={state.groups.error} />
      )}
      {state.security.error && (
        <Notification type="error" title={state.security.error} />
      )}

      {/* <Link
        to={{
          pathname: "/order/add",
          state: { background: { location: "/success" } },
        }}
      >
        llllllll
      </Link> */}
      {state.order.items.length === 0 ? (
        <Home
          modalDonationSetVisible={modalDonationSetVisible}
          modalPaymentSetVisible={modalPaymentSetVisible}
        />
      ) : (
        <Checkout
          modalAsk4DonationSetVisible={modalAsk4DonationSetVisible}
          modalAskEmailSetVisible={modalAskEmailSetVisible}
        />
      )}
      <AddPaymentModal
        open={modalPaymentVisible}
        // setOpen={modalPaymentSetVisible}
      />
      <AddDonationModal
        open={modalDonationVisible}
        // setOpen={modalDonationSetVisible}
      />
      <Ask4DonationModal
        open={modalAsk4DonationVisible}
        setOpen={modalAsk4DonationSetVisible}
        ask4EmailSetVisible={modalAskEmailSetVisible}
      />
      <Ask4EmailModal open={modalAskEmail} setOpen={modalAskEmailSetVisible} />
      <Footer />
    </div>
  );
}

export default Main;

export function Header() {
  return (
    <div
      id="header"
      className="container flex justify-between max-w-5xl px-4 py-4 mx-auto sm:px-6 lg:px-8"
    >
      <UserBlock />
      <div>
        <img
          src={logo}
          className="h-14 sm:h-18"
          alt="Movimiento Scout del Uruguay"
        />
      </div>
    </div>
  );
}

export function Footer() {
  return (
    <div id="footer">
      <div className="container flex max-w-5xl px-4 py-2 mx-auto mt-10 border-t border-gray-200 sm:px-6 lg:px-8">
        <img
          src={wosm}
          alt="Organización Mundial del Movimiento Scout"
          className="h-8 mr-5 md:h-10"
        />
        <p className="flex-1 text-xs text-gray-700">
          <span className="font-semibold">Movimiento Scout del Uruguay</span>
          <br />
          Andrés Martínez Trueba 1187
          <br />
          <a href="tel:+59824108840">(+598) 2410 8840</a>
          <br />
          <a href="mailto:pagos@msu.edu.uy" className="text-blue-600 underline">
            pagos@msu.edu.uy
          </a>
        </p>
        <div className="invisible md:visible">
          <h3 className="mb-2 text-xs font-semibold text-gray-700">
            Con el apoyo de
          </h3>
          <img
            src={microsoft}
            alt="Microsoft"
            className="inline-block mr-5 h-7"
          />
          <img
            src={mercadopago}
            alt="MercadoPago"
            className="inline-block h-7"
          />
        </div>
      </div>

      <div className="bg-gray-100 mt-2">
        <div className="container flex items-center max-w-5xl px-4 py-1 mx-auto sm:px-6 lg:px-8">
          <p className="flex-1 text-sm text-gray-600">
            Hecho con <img src={heart} alt="amor" className="inline h-3" /> por
            y para Scouts
          </p>
          <a
            href="https://www.facebook.com/MovimientoScoutdelUruguay"
            rel="noreferrer"
            target="_blank"
          >
            <img src={fb} alt="" className="h-4 mx-1" />
          </a>
          <a
            href="https://www.instagram.com/msu_oficial/"
            rel="noreferrer"
            target="_blank"
          >
            <img src={insta} alt="" className="h-4 mx-1" />
          </a>
          <a
            href="https://twitter.com/MScoutUy"
            rel="noreferrer"
            target="_blank"
          >
            <img src={tw} alt="" className="h-4 mx-1" />
          </a>
        </div>
      </div>
    </div>
  );
}
