/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import spinner from "../../img/icons/spinner-third-regular.svg";
import { useDispatch, useSelector } from "react-redux";
import { addItem } from "../../app/orderSlice";
import { useForm } from "react-hook-form";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router";
import { XIcon } from "@heroicons/react/outline";

export default function AddDonationModal({ open }) {
  // @ts-ignore
  const member = useSelector((state) => state.member);
  // @ts-ignore
  const groups = useSelector((state) => state.groups);
  // @ts-ignore
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: { amount: 500 },
  });

  const close = () => {
    reset();
    history.push("/");
  };

  const onSubmit = (data) => {
    dispatch(
      addItem({
        type: "donation",
        ...data,
        group: JSON.parse(data.group),
      })
    );
    close();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        open={open}
        onClose={close}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 pt-3 pr-3 text-gray-800">
                <button
                  type="button"
                  className="focus:outline-none focus:ring-0 "
                  onClick={() => close()}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="w-5 h-5" aria-hidden="true" />
                </button>
              </div>

              <div className="pb-4 border-b border-gray-300">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Ingresa los detalles de tu donación
                </Dialog.Title>
                <div>
                  <p className="text-sm text-justify text-gray-500">
                    El monto que decidas donar irá de manera integra al grupo
                    que elijas, ayudando a que este siga funcionando y
                    creciendo.
                  </p>
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-2">
                  <div className="sm:flex">
                    <div className="sm:w-1/3">
                      <label
                        htmlFor="amount"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Monto
                      </label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <span className="text-gray-500 sm:text-sm">$</span>
                        </div>
                        <input
                          {...register("amount", {
                            required: true,
                            pattern: /^\d+$/,
                          })}
                          type="text"
                          name="amount"
                          id="amount"
                          className="block w-full h-10 pr-12 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 pl-7 sm:text-sm"
                          aria-describedby="price-currency"
                        />
                        {errors.amount && (
                          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            <ExclamationCircleIcon
                              className="w-5 h-5 text-red-500"
                              aria-hidden="true"
                            />
                          </div>
                        )}
                      </div>
                      {errors.amount && errors.amount.type === "required" && (
                        <p className="mt-2 text-sm text-red-600">
                          Ingresa un monto.
                        </p>
                      )}
                      {errors.amount && errors.amount.type === "pattern" && (
                        <p className="mt-2 text-sm text-red-600">
                          Indica una cantidad válida.
                        </p>
                      )}
                    </div>
                    <div className="sm:ml-2">
                      <label
                        htmlFor="group"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Grupo Scout
                      </label>
                      <div className="mt-1 mb-2">
                        <select
                          {...register("group", {
                            required: true,
                          })}
                          className="block w-full h-10 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                          <option key={0} value="null">
                            Movimiento Scout del Uruguay
                          </option>
                          {groups.data.map((group) => {
                            return (
                              <option
                                key={group.id}
                                value={JSON.stringify(group)}
                              >
                                {group.name} ({group.region})
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <label
                    htmlFor="donor"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Tu nombre (opcional)
                  </label>
                  <div className="mt-1 mb-2">
                    <input
                      {...register("donor", {
                        required: false,
                      })}
                      id="donor"
                      className="block w-full h-10 p-2 placeholder-gray-300 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Deja un mensaje (opcional)
                  </label>
                  <div className="mt-1 mb-2">
                    <textarea
                      {...register("message", {
                        required: false,
                      })}
                      id="message"
                      className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="inline-flex justify-center w-full px-4 py-4 mt-3 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm sm:col-start-2 hover:bg-indigo-700 sm:mt-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {member.requesting && (
                      <img
                        alt="loading"
                        src={spinner}
                        className="w-5 h-5 mr-3 -ml-1 animate-spin"
                        aria-hidden="true"
                      />
                    )}
                    Aceptar
                  </button>
                  <button
                    type="reset"
                    className="inline-flex justify-center w-full px-4 py-4 mt-3 text-base font-medium text-gray-700 bg-white border border-transparent border-gray-300 rounded-md shadow-sm sm:col-start-1 hover:bg-gray-50 sm:mt-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => close()}
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
