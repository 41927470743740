/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationCircleIcon, XIcon } from "@heroicons/react/outline";
import spinner from "../../img/icons/spinner-third-regular.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchMember } from "../../app/memberSlice";
import { Controller, useForm } from "react-hook-form";
import MoreInfo from "../helper/MoreInfo";
import Notification from "../helper/Notification";
import { useHistory } from "react-router";
import { documentNormalize } from "../helper/functions";
import { dismissError } from "../../app/memberSlice";

export default function AddPaymentModal({ open = false }) {
  // @ts-ignore
  const member = useSelector((state) => state.member);
  // @ts-ignore
  const order = useSelector((state) => state.order);
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
    control,
  } = useForm();

  const close = () => {
    reset();
    dispatch(dismissError());
    !order.requesting && history.push("/");
  };

  const onSubmit = (data) => {
    dispatch(fetchMember(data, close));
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        open={open}
        onClose={close}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 pt-3 pr-3 text-gray-800">
                <button
                  type="button"
                  className="focus:outline-none focus:ring-0 "
                  onClick={() => close()}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="w-5 h-5" aria-hidden="true" />
                </button>
              </div>

              {member.error && (
                <Notification type="error" title={member.error} />
              )}
              <div className="pb-4 border-b border-gray-300 ">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {order.payers === 0
                    ? "Ingresa tu información"
                    : "Ingresa los datos del miembro"}
                </Dialog.Title>
                <div>
                  {order.payers === 0 ? (
                    <p className="text-sm text-justify text-gray-500">
                      Consultaremos nuestra base de datos para saber el monto
                      que te corresponde abonar. Recuerda que para pagar tu
                      anualidad debes haber hecho tu{" "}
                      <a
                        href="https://sherpa.msu.edu.uy/afiliacion"
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-600 underline"
                      >
                        solicitud de afiliación
                      </a>{" "}
                      anteriormente.
                    </p>
                  ) : (
                    <p className="text-sm text-justify text-gray-500">
                      Recuerda que solo puedes agregar miembros que sean parte
                      de tu nucleo familiar.
                    </p>
                  )}
                </div>
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-3">
                  <label
                    htmlFor="cedula"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Cédula de identidad
                  </label>
                  <div className="relative mt-1 ">
                    {/* <input
                      {...register("document", {
                        required: true,
                        pattern: /^(\d\.)?\d{3}\.\d{3}-\d$/,
                      })}
                      className="block w-full p-2 placeholder-gray-300 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="1.234.567-8"
                    /> */}
                    <Controller
                      control={control}
                      name="document"
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <input
                          onChange={onChange}
                          onBlur={(e) =>
                            onChange(documentNormalize(e.target.value))
                          }
                          className="block w-full h-10 p-2 placeholder-gray-300 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          value={value}
                        />
                      )}
                    />
                    {errors.document && (
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <ExclamationCircleIcon
                          className="w-5 h-5 text-red-500"
                          aria-hidden="true"
                        />
                      </div>
                    )}
                  </div>
                  {errors.document && errors.document.type === "required" && (
                    <p className="mt-2 text-sm text-red-600">
                      Ingresa el numero de cedula.
                    </p>
                  )}
                  {errors.document && errors.document.type === "pattern" && (
                    <p className="mt-2 text-sm text-red-600">
                      Debes incluir puntos y guiones.
                    </p>
                  )}
                </div>
                <div className="mt-3">
                  <label
                    htmlFor="nacimiento"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Fecha de nacimiento
                  </label>
                  <div className="relative mt-1">
                    <input
                      type="date"
                      {...register("birthDate", {
                        required: true,
                      })}
                      className="block w-full h-10 p-2 placeholder-gray-300 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="9/12/1990"
                    />
                    {errors.birthDate && (
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <ExclamationCircleIcon
                          className="w-5 h-5 text-red-500"
                          aria-hidden="true"
                        />
                      </div>
                    )}
                  </div>
                  {errors.birthDate && errors.birthDate.type === "required" && (
                    <p className="mt-2 text-sm text-red-600">
                      Ingresa la fecha de nacimiento.
                    </p>
                  )}
                  {errors.birthDate && errors.birthDate.type === "pattern" && (
                    <p className="mt-2 text-sm text-red-600">
                      El formato no es correcto.{" "}
                      <MoreInfo body="La fecha debe escribirse con el formato 30/5/2005." />
                    </p>
                  )}
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="inline-flex justify-center w-full px-4 py-4 mt-3 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm sm:col-start-2 hover:bg-indigo-700 sm:mt-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {member.requesting && (
                      <img
                        alt="loading"
                        src={spinner}
                        className="w-5 h-5 mr-3 -ml-1 animate-spin"
                        aria-hidden="true"
                      />
                    )}
                    Aceptar
                  </button>
                  <button
                    type="reset"
                    className="inline-flex justify-center w-full px-4 py-4 mt-3 text-base font-medium text-gray-700 bg-white border border-transparent border-gray-300 rounded-md shadow-sm sm:col-start-1 hover:bg-gray-50 sm:mt-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => close()}
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
