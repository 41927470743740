import { BeakerIcon } from "@heroicons/react/solid";
import { createSlice } from "@reduxjs/toolkit";
import api from "./api";
import { addItem } from "./orderSlice";

export const memberSlice = createSlice({
  name: "member",
  initialState: {
    requesting: false,
    error: null,
  },
  reducers: {
    requestStart: (state, action) => {
      state.requesting = true;
      state.error = "";
    },
    requestSuccess: (state, action) => {
      state.requesting = false;
      state.error = "";
    },
    requestError: (state, action) => {
      state.requesting = false;
      state.error = action.payload;
    },
    dismissError: (state) => {
      state.error = "";
    },
  },
});

export const fetchMember = (data, close) => (dispatch) => {
  dispatch(requestStart());
  api
    .get(
      `/miembros/find?cedula=${data.document}&nacimiento=${data.birthDate}&year=${process.env.REACT_APP_ANUALIDAD_YEAR}`
    )
    .then((response) => {
      const member = response.data;
      //Encontró el miembro pero este ya había pagado
      if (member.annualFeePaid) {
        dispatch(requestError("El miembro ya pagó su anualidad de este año."));
      } else {
        //Calcula el monto que le corresponde pagar al miembro
        //Por su categoria
        let price =
          member.type === "educando"
            ? Number(process.env.REACT_APP_ANUALIDAD_EDUCANDO)
            : Number(process.env.REACT_APP_ANUALIDAD_ADULTO);
        //Si tiene una beca la aplica ...
        let subvention = member.subventions.find(
          (subvention) =>
            subvention.year === Number(process.env.REACT_APP_ANUALIDAD_YEAR)
        );
        subvention = subvention
          ? {
              label: "BECA",
              rate: subvention.discount,
              amount: (subvention.discount * price) / 100,
            }
          : { label: "BECA", rate: 0, amount: 0 };

        // const amount =
        //   response.data.type === "educando"
        //     ? Number(process.env.REACT_APP_ANUALIDAD_EDUCANDO)
        //     : Number(process.env.REACT_APP_ANUALIDAD_ADULTO);
        dispatch(
          addItem({
            type: "payment",
            member: member,
            price: price,
            subvention: subvention,
            amount: price - subvention.amount,
          })
        );
        dispatch(requestSuccess(response.data));
        close();
      }
    })
    .catch((error) => {
      dispatch(
        requestError(
          error.response?.status === 404
            ? "No se encuentra el miembro. Verifica que los datos sean correctos y que el miembro haya hecho su solicitud de afiliación."
            : "Error al consultar Sherpa."
        )
      );
    });
};

// Action creators are generated for each case reducer function
export const { requestError, requestStart, requestSuccess, dismissError } =
  memberSlice.actions;

export default memberSlice.reducer;
