import { BlobServiceClient } from "@azure/storage-blob";
import { useState } from "react";
import { v4 } from "uuid";

// BLOB SERVICE
const blobServiceClient = new BlobServiceClient(
  `https://${process.env.REACT_APP_AZURE_STORAGE_URL}?${process.env.REACT_APP_AZURE_STORAGE_SAS}`
);
const containerClient = blobServiceClient.getContainerClient(
  `${process.env.REACT_APP_AZURE_STORAGE_CONTAINER}`
);

export function useUploader() {
  const [uploading, setUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState();

  const upload = async (files) => {
    if (!files || !files[0]) throw new Error("No files to upload");

    setUploading(true);

    // for (let i = 0; i < files.length; i++) {
    const fileName = v4() + "." + (files[0]?.name.split(".").pop() || "");

    const blockBlobClient = containerClient.getBlockBlobClient(fileName);
    blockBlobClient.setHTTPHeaders({ blobContentType: files[0].type });
    await blockBlobClient.uploadData(files[0]);
    setUploadedFile(
      `https://${process.env.REACT_APP_AZURE_STORAGE_URL}/${process.env.REACT_APP_AZURE_STORAGE_CONTAINER}/${fileName}`
    );
    setUploading(false);
  };

  return { uploading, uploadedFile, upload };
}
